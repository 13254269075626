import React from 'react';

import { UserAvatar } from '../UserAvatar';
import { UserLink } from '../UserLink';

import { UserAvatarLinkUser } from './UserAvatarLink.types';

interface UserAvatarLinkDefaultProps {
  user: UserAvatarLinkUser;
}

interface UserAvatarLinkWithClassNameProps {
  className: string;
  avatarType?: never;
  width?: number;
  height?: number;
}

interface UserAvatarLinkWithAvatarTypeProps {
  className?: never;
  avatarType: 'main' | 'xl' | 'xs';
  width?: number;
  height?: number;
}

type UserAvatarLinkProps = UserAvatarLinkDefaultProps &
  (UserAvatarLinkWithClassNameProps | UserAvatarLinkWithAvatarTypeProps);

function UserAvatarLink({
  className,
  avatarType,
  user,
  width,
  height
}: UserAvatarLinkProps) {
  if (!user) {
    return null;
  }

  return (
    <UserLink user={user} className="h-fit w-fit">
      <UserAvatar
        className={className}
        type={avatarType}
        user={user}
        width={width}
        height={height}
      />
    </UserLink>
  );
}

export default UserAvatarLink;
