import { FetchItemCacheKey, FetchItemsGqlQuery } from '../../../../types';

import {
  FetchUsersGqlQuery,
  FetchUsersCacheKey,
  FetchUsersFilters,
  FetchUsersSort,
  FetchUsersPage,
  FetchUsersLimit
} from '../../usersTypes';

import { useIndexQuery } from '../../../common/hooks/base/reactQuery/useIndexQuery';

import {
  INITIAL_USERS_FILTERS,
  INITIAL_USERS_LIMIT,
  INITIAL_USERS_PAGE,
  INITIAL_USERS_SORT
} from '../../usersConstants';

interface UsersDefaultOptions {
  cacheKey: FetchUsersCacheKey;
  query: FetchUsersGqlQuery;
  initialFilters?: FetchUsersFilters;
  initialSort?: FetchUsersSort;
  initialPage?: FetchUsersPage;
  initialLimit?: FetchUsersLimit;
  options?: {
    cacheTime?: number;
    staleTime?: number;
    keepPreviousData?: boolean;
  };
}

interface UsersWithPrefetchItemOptions {
  fetchItemCacheKey: FetchItemCacheKey;
  fetchItemQuery: FetchItemsGqlQuery;
}

interface UsersWithoutPrefetchItemOptions {
  fetchItemCacheKey?: never;
  fetchItemQuery?: never;
}

type UsersOptions = UsersDefaultOptions &
  (UsersWithPrefetchItemOptions | UsersWithoutPrefetchItemOptions);

const scope = 'users';

function usePaginatedUsers<ItemType>({
  cacheKey,
  query,
  initialFilters = INITIAL_USERS_FILTERS,
  initialSort = INITIAL_USERS_SORT,
  initialPage = INITIAL_USERS_PAGE,
  initialLimit = INITIAL_USERS_LIMIT,
  fetchItemCacheKey,
  fetchItemQuery,
  options = {}
}: UsersOptions) {
  const {
    data,
    items,
    itemsError,
    itemsTotalCount,
    isFetched,
    isLoading,
    isPlaceholderData,
    currentPage,
    currentLimit,
    currentFilters,
    currentSort,
    changeItemsFilters,
    clearItemsFilters,
    filterItems,
    prefetchItems,
    sortItems,
    paginateItems,
    limitItems,
    updateItemCache,
    prefetchItem
  } = useIndexQuery<ItemType>({
    scope,
    query,
    cacheKey,
    initialFilters,
    initialLimit,
    initialPage,
    initialSort,
    fetchItemCacheKey,
    fetchItemQuery,
    options
  });

  return {
    usersData: data,
    users: items,
    usersError: itemsError,
    usersTotalCount: itemsTotalCount,
    usersFetched: isFetched,
    usersLoading: isLoading,
    usersIsPlaceholderData: isPlaceholderData,
    usersFilters: currentFilters,
    usersSort: currentSort,
    usersPage: currentPage,
    usersLimit: currentLimit,
    filterUsers: filterItems,
    changeUsersFilters: changeItemsFilters,
    clearUsersFilters: clearItemsFilters,
    sortUsers: sortItems,
    paginateUsers: paginateItems,
    limitUsers: limitItems,
    prefetchUsers: prefetchItems,
    prefetchUser: prefetchItem,
    updateUserCache: updateItemCache
  };
}

export default usePaginatedUsers;
