import { gql } from 'graphql-request';

export const GET_INVITE_USER_SEARCHES_QUERY = gql`
  query InviteUserSearches(
    $filters: InviteUserSearchesFilters
    $sort: [InviteUserSearchesSortEnum!]
    $limit: Int
    $offset: Int
  ) {
    inviteUserSearches(
      filters: $filters
      sort: $sort
      offset: $offset
      limit: $limit
    ) {
      nodes {
        id
        uuid
        userType
        userId
        user {
          uuid
          nanoId
          fullName
          image {
            uuid
            file
          }
        }
        searchedUserId
        searchedUser {
          id
          uuid
          nanoId
          fullName
          financeRoleName
          image {
            uuid
            file
          }
        }
        createdAt
        updatedAt
      }
      paginationInfo {
        currentPage
        firstPage
        lastPage
        limitValue
        nextPage
        outOfRange
        prevPage
        totalCount
        totalPages
      }
    }
  }
`;
