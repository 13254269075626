import size from 'lodash/size';

import { ID, UUID } from '../../../../../../../../types';
import { UsersTabType } from '../../../../../../../users/usersTypes';

import { useSearchResultsUsersList } from './hooks/useSearchResultsUsersList';

import { CheckableUsersList } from '../CheckableUsersList';
import { CheckableUsersListItemUserType } from '../CheckableUsersListItem';

import { AlertMessage } from '../../../../../../../../helpers/AlertMessage';
import { LoadingSkeleton } from '../../../../../../../../helpers/LoadingSkeleton';
import { Translate } from '../../../../../../../../helpers/Translate';

import { words } from '../../../../../../../../locales/keys';

interface SearchResultsUsersListProps {
  fullName?: string;
  groupIds?: ID[];
  invitedUserUuids?: UUID[];
  onCheck?: (user: CheckableUsersListItemUserType, checked: boolean) => void;
  onCheckAll?: (
    users: CheckableUsersListItemUserType[],
    checked: boolean
  ) => void;
  selectedUserIds?: ID[];
  tab: UsersTabType;
  teamIds?: ID[];
}

function SearchResultsUsersList({
  fullName,
  groupIds,
  invitedUserUuids,
  selectedUserIds,
  onCheck,
  onCheckAll,
  tab,
  teamIds
}: SearchResultsUsersListProps) {
  const { users, usersError, usersFetched } = useSearchResultsUsersList({
    fullName,
    groupIds,
    tab,
    teamIds
  });

  return (
    <div data-id="users-list-search-results" className="mt-6">
      <p className="font-light text-xs uppercase mb-2">
        <Translate id={words.searchResults} />
      </p>

      <LoadingSkeleton count={2} loaded={usersFetched}>
        {size(users) === 0 ? (
          <div className="text-center my-5">
            <Translate id={words.noResultsYet} />
          </div>
        ) : (
          <CheckableUsersList
            invitedUserUuids={invitedUserUuids}
            onCheck={onCheck}
            onCheckAll={onCheckAll}
            selectedUserIds={selectedUserIds}
            users={users}
          />
        )}
        <AlertMessage message={usersError} />
      </LoadingSkeleton>
    </div>
  );
}

export default SearchResultsUsersList;
