import compact from 'lodash/compact';
import get from 'lodash/get';
import includes from 'lodash/includes';
import last from 'lodash/last';
import uniq from 'lodash/uniq';

import { UpdateItemGqlQuery } from '../../../../../../../types';

import { graphQLClient } from '../../graphQLClients';

import { productionConsole } from '../../../../../../../utils/productionConsole';

interface UpdateItemProps<UpdateItemInput> {
  action: string;
  query: UpdateItemGqlQuery;
  queryInput: UpdateItemInput;
}

const successQueryStatuses = ['updated', 'success'];

export function updateItem<UpdateItemInput, UpdateItemResponse>({
  action,
  query,
  queryInput
}: UpdateItemProps<UpdateItemInput>) {
  productionConsole(
    'log',
    'updateItem',
    { action, queryInput },
    last(uniq(compact(query.match(/(mutation\s.+)\(/)))),
    JSON.stringify(queryInput)
  );

  return new Promise<UpdateItemResponse>((resolve, reject) => {
    return graphQLClient
      .request<UpdateItemResponse, UpdateItemInput>(query, queryInput)
      .then((response) => {
        if (includes(successQueryStatuses, get(response, `${action}.status`))) {
          return resolve(response);
        }

        reject(get(response, `${action}.errors`));
      })
      .catch((reason) => reject(reason));
  });
}
