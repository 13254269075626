import { gql } from 'graphql-request';

export const SEARCH_USERS_QUERY = gql`
  query SearchUsers(
    $filters: UsersFilters
    $sort: [UsersSortEnum!]
    $limit: Int
    $offset: Int
  ) {
    users(filters: $filters, sort: $sort, offset: $offset, limit: $limit) {
      paginationInfo {
        currentPage
        firstPage
        lastPage
        limitValue
        nextPage
        outOfRange
        prevPage
        totalCount
        totalPages
      }
      nodes {
        id
        uuid
        nanoId
        fullName
        name
        surname
        email
        financeRoleName
        createdAt
        updatedAt
        image {
          uuid
          file
        }
      }
    }
  }
`;
