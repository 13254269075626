import React from 'react';
import cl from 'classnames';

import { ImageItemImageVersions } from '../../../../helpers/ImageHelper';
import { Files } from '../../../../utils/Files';

import { UserAvatarUser } from './UserAvatar.types';

interface UserAvatarClassNameProps {
  className: string;
  type?: 'main' | 'xl' | 'xs';
  user: UserAvatarUser;
  width?: number;
  height?: number;
}

interface UserAvatarTypedProps {
  className?: string;
  type: 'main' | 'xl' | 'xs';
  user: UserAvatarUser;
  width?: number;
  height?: number;
}

type UserAvatarProps = UserAvatarClassNameProps | UserAvatarTypedProps;

function UserAvatar({ className, type, user, width, height }: UserAvatarProps) {
  if (!user) {
    return null;
  }

  return (
    <img
      className={
        className ||
        cl({
          'max-w-none h-9 w-9 rounded-full bg-gray-200 dark:bg-gray-800':
            type === 'main',
          'max-w-none h-9 w-9 rounded-full ring-2 ring-white dark:ring-gray-900 group-hover:ring-gray-200 dark:group-hover:ring-gray-700 bg-gray-200 dark:bg-gray-800':
            type === 'xl',
          'max-w-none h-6 w-6 rounded-full ring-2 ring-white dark:ring-gray-900 group-hover:ring-gray-200 dark:group-hover:ring-gray-700 bg-gray-200 dark:bg-gray-800':
            type === 'xs'
        })
      }
      src={Files.image(user.image, ImageItemImageVersions.Thumb160x160)}
      alt={user.fullName}
      title={user.fullName}
      width={width}
      height={height}
    />
  );
}

export default UserAvatar;
