import React, { ReactNode } from 'react';

import { ClassName } from '../../../../types';

import { UserLinkUser } from './UserLink.types';

import { NextLinkHelper } from '../../../../helpers/links/NextLinkHelper';

import { UserPath } from '../../../users/UserPath';

interface UserLinkDefaultProps {
  user: UserLinkUser;
  className?: ClassName;
}

interface UserLinkWithChildrenProps {
  text?: never;
  children: ReactNode;
}

interface UserLinkWithTextProps {
  text: string;
  children?: never;
}

type UserLinkProps = UserLinkDefaultProps &
  (UserLinkWithChildrenProps | UserLinkWithTextProps);

function UserLink({ className, user, text, children }: UserLinkProps) {
  if (!user) {
    return null;
  }

  return (
    <NextLinkHelper
      className={className}
      href={UserPath.show(user.nanoId)}
      title={user.fullName}
    >
      {text || children}
    </NextLinkHelper>
  );
}

export default UserLink;
